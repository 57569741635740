import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Headers, RequestOptions, Response } from '@angular/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PdfViewerComponent } from '@syncfusion/ej2-angular-pdfviewer';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { ActivityTab } from '../../gestion/activity/activity.enums';
import { EProviderSendStatus } from '../../gestion/reservation/reservation.enums';
import { DataTableJsColumn, DataTableJsColumnOperation, DataTableJsColumnOrderDirection, IDataTableJsParam } from '../../interface/DataTableJs';
import { IDoc } from '../../interface/doc';
import { BasicComboItem } from '../../interface/FilterColumn';
import { InfoTab } from '../../interface/InfoTab';
import { INavegateCreateReservation, NavegationDom } from '../../Interface/navegationDom';
import { ICurrency, IProvider } from '../../Interface/provider';
import { IValidatedUser } from '../../interface/user';
import { NotifyService } from '../../services/notify/notify.service';
import { DBOperation } from '../../shared/mix/enum';
import { TableNames } from '../components/file-manager/file-manager.enums';
import { ModalMessages } from '../components/modal-message/modal-messages';
import { ModalMessageType } from '../components/modal-message/modal-messages.enum';
import { MyNotification } from '../components/notificacion/notification.class';
import { AvailableEnums, ControlDomId, CookieKeys, DateFilter, EntityType, IChangeTabDetect, LocalStorageKey, StateType } from '../mix/enum';
import { Global } from '../mix/global';
import { ValOfEnumPipe } from '../pipes/shared-pipes';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IFrame } from '../models/iFrame.model';
import { CHANGE_DATE_FORMAT_LIST, CHANGE_DATE_WITH_HOURS_FORMAT_LIST, EDATE_FORMAT } from 'app/constants/date.constant';
import { Language } from '../models/language.model';
import { CurrencyService } from 'app/core/http-services/currency/currency.service';

declare const $: any;

interface ICookieOpts {
    hours?: number;
    path?: string;
    httpOnly?: boolean;
    storeUnencoded?: boolean;
    domain?: string;
    secure?: boolean;
}
@Injectable()
export class AppGlobalDataService {
    private lang = new BehaviorSubject<string>('es');
    private bearer: string = '';
    private just_logged: boolean = false;
    private active_division_id: number;
    private activeDivisionDescription: string;
    private activeCompanyId: number;
    private superLoading: boolean;
    notification: MyNotification;

    @Output() listen_new_ptab = new EventEmitter();

    userProvider: IProvider;
    showNotifications = false;

    constructor(
        public router: Router,
        private _http: HttpClient,
        private _notifyService: NotifyService,
        private sanitizer: DomSanitizer,
        private _currencyService: CurrencyService
    ) {
        this.notification = new MyNotification();
    }

    public startSuperLoading(): void {
        this.superLoading = true;
    }

    public stopSuperLoading(): void {
        this.superLoading = false;
    }

    public getSuperLoading(): boolean {
        return this.superLoading;
    }

    public getLastVisitedFlyTab(): number {
        let it = this.getClientStorage(CookieKeys.last_fly_tab, false);
        if (!this.isset(it)) {
            it = 0;
            this.putClientStorage(CookieKeys.last_fly_tab, it);
        }
        return it;
    }

    public setLastVisitedFlyTab(it: number) {
        this.putClientStorage(CookieKeys.last_fly_tab, it);
    }
    //metodos para trastear con el usuario
    public getUserPermissions(): Observable<any> {
        let url = this.getSafeEndpoint(Global.BASE_PERMISOS_USER_ENDPOINT + 'GetPermissionsByUserPrincipal');
        return this._http.post(url, '').pipe(
            map((resp: Response) => {
                let clean_response = this.getResponseHttpClient(resp);

                if (this.getResponseHttpClient(clean_response)) {
                    this.putClientStorage(CookieKeys.permisissions, clean_response, { hours: 24 }, true);
                    return clean_response;
                } else {
                    return clean_response;
                }
            }),
            catchError((response: Response) => observableThrowError(this.getMessageErrorHttpClient(response)))
        );
    }

    public getUserVisibleTargets(): EntityType[] {
        let allowed_entityes: EntityType[] = [EntityType.pricelist, EntityType.provider];
        return allowed_entityes;
    }

    public user(): IValidatedUser {
        let us = this.getClientStorage(CookieKeys.user, true);
        return us;
    }

    public getUserId(): number {
        let us = this.user();
        return this.isset(us) && this.isset(us.Id) ? us.Id : 0;
    }

    public getUserCompleteName(): string {
        let us = this.user();
        return this.isset(us) && this.isset(us.CompleteName) ? us.CompleteName : '';
    }

    public getUserName(): string {
        let us = this.user();
        return this.isset(us) && this.isset(us.UserName) ? us.UserName : '';
    }

    public getUserDivisionId(): any {
        if (!this.isset(this.active_division_id)) {
            let us = this.user();
            this.active_division_id = this.isset(us) && this.isset(us.ActiveDivision) ? us.ActiveDivision.Id : 0;
        }
        return this.active_division_id;
    }

    public getUserDivisionDescription(): any {
        if (!this.isset(this.activeDivisionDescription)) {
            const user = this.user();
            this.activeDivisionDescription = this.isset(user) && this.isset(user.ActiveDivision) ? user.ActiveDivision.Description : '';
        }
        return this.activeDivisionDescription;
    }

    /**
     * @description Get user active division fiscal name
     * @returns string
     */
    public getUserDivisionFiscalName(): string {
        if (this.activeDivisionDescription) {
            return '';
        }

        const USER: IValidatedUser = this.user();

        if (!USER || !USER.ActiveDivision || !USER.ActiveDivision.FiscalName || !USER.ActiveDivision.FiscalName.length) {
            return '';
        }

        return USER.ActiveDivision.FiscalName;
    }

    public getUserCompanyDivisionId(): any {
        if (!this.isset(this.activeCompanyId)) {
            let us = this.user();
            this.activeCompanyId = this.isset(us) && this.isset(us.ActiveDivision) ? us.ActiveDivision.CompanyDivisionId : 0;
        }
        return this.activeCompanyId;
    }

    public getUserDivisionCurrencyId(): any {
        let dvn = this.getUserDivision();
        return this.isset(dvn) && this.isset(dvn.CurrencyId) ? dvn.CurrencyId : Global.DEFAULT_CURRENCY;
    }

    public getUserDivisionCurrencyCode(): any {
        let dvn = this.getUserDivision();
        return this.isset(dvn) && this.isset(dvn.CurrencyCode) ? dvn.CurrencyCode : Global.DEFAULT_CURRENCY;
    }

    public getUserDivision(): any {
        let us = this.user();
        return this.isset(us) && this.isset(us.ActiveDivision) ? us.ActiveDivision : null;
    }

    public getUserLanguage(): any {
        const USER = this.user();
        return this.isset(USER) && this.isset(USER.ActiveLanguage) ? USER.ActiveLanguage : null;
    }

    /**
     *
     * @description Get user active division currency
     * @returns ICurrency
     */
    public getUserCurrency(): ICurrency {
        const USER_CURRENCY = this.getClientStorage(CookieKeys.currency, true);
        return USER_CURRENCY;
    }

    public setUserDivision(id_dnv) {
        let us = this.user();
        if (this.isset(us) && this.isset(us.ActiveDivision)) {
            us.ActiveDivision.Id = id_dnv;
            this.active_division_id = id_dnv;
        }
    }

    public setUserCompanyDivisionId(companyDivisionId) {
        let us = this.user();
        if (this.isset(us) && this.isset(us.ActiveDivision)) {
            us.ActiveDivision.CompanyDivisionId = companyDivisionId;
            this.activeCompanyId = companyDivisionId;
        }
    }

    public setUserCompanyDivisionDescription(companyDivisionDescription) {
        let us = this.user();
        if (this.isset(us) && this.isset(us.ActiveDivision)) {
            us.ActiveDivision.Description = companyDivisionDescription;
            this.activeDivisionDescription = companyDivisionDescription;
        }
    }

    public getUserEmail(): string {
        let us = this.user();
        return this.isset(us) && this.isset(us.Email) ? us.Email : '';
    }

    public loggedUser() {
        if (this.getToken() != null) {
            return true;
        } else {
            return false;
        }
    }

    public getUserGroup(): string {
        let us = this.user();
        return this.isset(us) && this.isset(us.GroupCode) ? us.GroupCode : '';
    }

    public isProviderLogged(): boolean {
        return this.getUserGroup() === Global.PROVIDER_USER_GROUP || this.isDelegatedProviderLoged();
    }

    public isDelegatedProviderLoged(): boolean {
        return this.getUserGroup() === Global.DELEGATED_PROVIDER_USER_GROUP;
    }

    public setUserProvider(newUserProvider: IProvider): void {
        this.userProvider = newUserProvider;
    }
    public getUserProvider(): IProvider {
        return this.userProvider;
    }

    public getPermissions(): string[] {
        let permissions: { Code: string }[] = this.getClientStorage(CookieKeys.permisissions, true);

        if (permissions.length > 0) {
            return permissions.map((permission) => permission.Code);
        }

        return [];
    }

    public initializeUserProvider(): void {
        this.GetProvider(Global.BASE_PROVEEDOR_ENDPOINT + 'GetUserProvider').subscribe(
            (response: IProvider) => {
                if (this.getResponseHttpClient(response)) {
                    this.setUserProvider(response);
                } else {
                    this._notifyService.falloMessage('NOTIFICACION.PROBLEMA');
                }
            },
            (error) => {
                this._notifyService.falloMessage('NOTIFICACION.PROBLEMA');
            }
        );
    }

    public isChoferLogged(): boolean {
        return this.getUserGroup() === Global.CHOFER_USER_GROUP;
    }

    public isClientLogged(): boolean {
        return this.getUserGroup() === Global.CLIENT_USER_GROUP;
    }

    public loadCustomerProviderCSGLoggedUser(userID?: number): Observable<any> {
        userID = userID != null ? userID : this.getUserId();
        let url = this.getSafeEndpoint(Global.BASE_PROVEEDOR_ENDPOINT + '/GetByUserId?userID=' + userID);
        return this._http.get(url).pipe(
            map((resp: Response) => {
                return this.getResponseHttpClient(resp);
            }),
            catchError((response: Response) => observableThrowError(this.getMessageErrorHttpClient(response)))
        );
    }

    public setInfoLoggedUser(data_usr?: IValidatedUser, validated_token?: string) {
        if (validated_token != null) {
            this.setToken(validated_token);
        }
        if (data_usr != null) {
            //nos vienen datos de usuario logado
            if (data_usr.ActiveLanguage.Code.length > 0) {
                //el idioma configurado por BBDD
                this.changeLang(data_usr.ActiveLanguage.Code.toLowerCase());
            }
            this.active_division_id = data_usr.ActiveDivision.Id;
            this.putClientStorage(CookieKeys.user, data_usr);
        }
    }

    /**
     * @description Set currency in local storage
     * @param {number} [currencyId]
     * @returns void
     */
    public setCurrency(currencyId?: number): void {
        const IS_THERE_CURRENCY = this.isset(currencyId);
        if (!IS_THERE_CURRENCY) {
            return;
        }

        this._currencyService.getById(currencyId).subscribe((currency: ICurrency) => {
            this.putClientStorage(CookieKeys.currency, currency);
        });
    }

    /**
     * @description Get currency from local storage
     * @returns {*}  {ICurrency}
     */
    public getCurrency(): ICurrency {
        return this.getClientStorage(CookieKeys.currency);
    }

    public isJustLogin(): boolean {
        return this.just_logged;
    }

    public jusLogin(state: boolean) {
        this.just_logged = state;
    }

    public getLang() {
        return this.lang.asObservable();
    }

    public getBearer(): string {
        return this.bearer;
    }

    public setBearer(b: string) {
        this.bearer = b;
    }

    public getToken(): string {
        return this.getClientStorage(CookieKeys.token);
    }

    public setToken(tkn: string) {
        this.putClientStorage(CookieKeys.token, tkn);
    }

    public deleteToken() {
        this.deleteClientStorage(CookieKeys.token);
    }

    public changeLang(language: string) {
        if (language.indexOf('-') > -1) {
            language = language.substr(0, language.indexOf('-'));
        }

        if (this.getAvailableLangs().indexOf(language) === -1) {
            language = 'es';
        }

        this.lang.next(language);
        this.putClientStorage(CookieKeys.language, language, this.getCookieOpts(360));
    }

    public getAvailableLangs(): string[] {
        return ['en', 'es'];
    }

    public initLanguage() {
        let ln = this.getStringLang();
        this.lang.next(ln);
        if (!this.isset(this.getClientStorage(CookieKeys.language))) {
            this.putClientStorage(CookieKeys.language, this.lang.getValue(), this.getCookieOpts(360));
        }
    }

    getStringLang(): string {
        let ln: string = 'es';
        let aux = this.getClientStorage(CookieKeys.language);
        if (aux != null) {
            ln = aux;
        } else {
            if (!this.isset(this.lang.getValue())) {
                let nav_lang: string = navigator.language;
                if (nav_lang.indexOf('-') > -1) {
                    ln = nav_lang.substr(0, nav_lang.indexOf('-'));
                } else {
                    ln = nav_lang;
                }
                if (this.getAvailableLangs().indexOf(ln) === -1) {
                    ln = 'es';
                }
            } else {
                ln = this.lang.getValue();
            }
        }
        return ln;
    }

    //esta función para 'esperar' datos del backend
    awaitData() {
        return this.getLang();
    }

    //********metodos de operacion con fechas
    public getTimeDate(dt: any, separator?: string): string {
        let dat_interm = this.getSafeValidDate(dt);
        if (dat_interm != null) {
            separator = separator == null ? ':' : separator;
            return ('0' + dat_interm.getHours()).slice(-2) + separator + ('0' + dat_interm.getMinutes()).slice(-2);
        } else {
            return '';
        }
    }

    public getFormattedDate(dt: any, typo?: string, time?: boolean, separator?: string): string {
        if (dt != null) {
            if (typo == null) {
                typo = this.isset(this.lang.getValue()) ? this.lang.getValue() : 'es';
            }
            time = time == null ? false : time;
            separator = separator == null ? '-' : separator;
            let dat_interm = this.getSafeValidDate(dt);
            let format: string = '';
            if (dat_interm != null) {
                format = dat_interm;
                let m = dat_interm.getMonth() + 1;
                switch (typo) {
                    case 'es':
                        format = ('0' + dat_interm.getDate()).slice(-2) + separator + ('0' + m).slice(-2) + separator + dat_interm.getFullYear();
                        if (time) {
                            format += ' ' + ('0' + dat_interm.getHours()).slice(-2) + ':' + ('0' + dat_interm.getMinutes()).slice(-2);
                        }
                        break;
                    case 'bbdd': //para base de datos
                        format = dat_interm.getFullYear() + separator + ('0' + m).slice(-2) + separator + ('0' + dat_interm.getDate()).slice(-2);
                        if (time) {
                            format += 'T' + ('0' + dat_interm.getHours()).slice(-2) + ':' + ('0' + dat_interm.getMinutes()).slice(-2);
                        }
                        break;
                    default:
                        format = dat_interm.getFullYear() + separator + ('0' + m).slice(-2) + separator + ('0' + dat_interm.getDate()).slice(-2);
                        if (time) {
                            format += 'T' + ('0' + dat_interm.getHours()).slice(-2) + ':' + ('0' + dat_interm.getMinutes()).slice(-2);
                        }
                        break;
                }
            }
            return format;
        } else {
            return null;
        }
    }

    public getValidDateTime(dt: any): any {
        let aux_date: string = String(dt);
        let date_time: string = aux_date.indexOf('T') > -1 ? aux_date.substring(aux_date.indexOf('T')) : ''; //2005-10-13T00:00:00
        date_time = aux_date.indexOf(' ') > -1 ? aux_date.substring(aux_date.indexOf(' ') + 1) : ''; //2005-10-13 00:00:00
        dt = aux_date.replace(date_time.trim(), '').trim();
        try {
            let supported_separators: string[] = ['/', `,`, `-`, '.'];
            for (let separator of supported_separators) {
                if (dt.indexOf(separator) > -1) {
                    let dt_split: string[] = dt.split(separator);
                    if (dt_split[0].length === 4) {
                        //año en la posicion 0
                        dt = dt_split[0] + '-' + dt_split[1] + '-' + dt_split[2];
                    } else if (dt_split[2].length === 4) {
                        //año en la posicion 2
                        dt = dt_split[2] + '-' + dt_split[1] + '-' + dt_split[0];
                    } else if (dt_split[2].length > 1) {
                        //puede venirnos el formato 1/2/14
                        let aux_ayo: any = Number(dt_split[2]) > 70 ? '19' + dt_split[2] : '20' + dt_split[2];
                        dt = aux_ayo + '-' + ('0' + dt_split[1]).slice(-2) + '-' + ('0' + dt_split[0]).slice(-2);
                    } else {
                        dt = null;
                    }
                    break;
                }
            }
            if (dt != null) {
                if (date_time.length > 0) {
                    //si tenemos horas/minutos
                    dt += 'T' + date_time.trim();
                    return this.newDateRegardlessOffset(dt); //devuelve el formato Tue May 22 2018 15: 20: 00 GMT + 0200
                } else {
                    return new Date(dt);
                }
            } else {
                return null;
            }
        } catch (e) {
            return null;
        }
    }

    public newDateRegardlessOffset(dt: any): Date {
        //new Date(dt) devuelve el formato Tue May 22 2018 15: 20: 00 GMT + 0200
        let dte = new Date(dt);
        let offset = dte.getTimezoneOffset();
        var plus = offset < 0 ? true : false;
        let hours_offset = offset / 60;
        let mins_offset = offset % 60;
        if (plus) {
            //sumar a dte
            if (hours_offset > 0) {
                dte.setHours(dte.getHours() + hours_offset);
            }
            if (mins_offset > 0) {
                dte.setMinutes(dte.getMinutes() + mins_offset);
            }
        } else {
            //restar a dte
            if (hours_offset > 0) {
                dte.setHours(dte.getHours() - hours_offset);
            }
            if (mins_offset > 0) {
                dte.setMinutes(dte.getMinutes() - mins_offset);
            }
        }
        return dte;
    }

    public getSafeValidDate(dt): any {
        if (Object.prototype.toString.call(dt) === '[object Date]' && dt instanceof Date) {
            // it is a date
            if (isNaN(dt.getTime())) {
                // date is not valid , d.valueOf() could also work
                dt = dt != null ? this.getValidDateTime(dt) : null;
            }
        } else {
            // not a date
            dt = dt != null ? this.getValidDateTime(dt) : null;
        }
        return dt;
    }

    public formatDateWithMoment(date: Date | string, resultFormat: string, origiFormat?: string): Date | string {
        if (date !== undefined && date !== null) {
            if (this.isset(origiFormat)) {
                return moment(date, origiFormat).format(resultFormat);
            }
            return moment(date).format(resultFormat);
        }

        return null;
    }

    public validateDateWithMoment(date: Date | string, formats?: string[]): boolean {
        if (formats) {
            return moment(date, formats, true).isValid();
        }
        return moment(date).isValid();
    }

    public fromNowWithMoment(date: Date | string) {
        return moment(date).fromNow(true).toString().split(' ');
    }

    public momentNow(): any {
        return moment.now();
    }
    /**
     * @param  {string[]} fisrtDate Date on Array [YYYY, MM, DD]
     * @param  {string[]} secondDate Date on Array [YYYY, MM, DD]
     * @param  {any} segment Option for different dates (e.g: 'years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'), deafult is 'days'
     * @description Returns the difference between dates
     */
    public dateDiffWithMoment(fisrtDate: string[], secondDate: string[], segment: any = 'days'): any {
        return moment([parseInt(fisrtDate[2], 10), parseInt(fisrtDate[1], 10), parseInt(fisrtDate[0], 10)]).diff(
            [parseInt(secondDate[2], 10), parseInt(secondDate[1], 10), parseInt(secondDate[0], 10)],
            segment
        );
    }

    public afterDateWithMoment(firstDate: Date | string, secondDate: Date | string) {
        return moment(firstDate).isAfter(secondDate);
    }

    public getYearWithMoment(date: Date | string): any {
        return moment(date).year();
    }

    public logoutUser() {
        if (this.isChoferLogged()) {
            this.router.navigate(['/mobile-login']);
        } else {
            this.router.navigate(['/login']);
        }

        this.deleteClientStorage(CookieKeys.token);
        this.deleteClientStorage(CookieKeys.user);
        this.deleteClientStorage(CookieKeys.permisissions);
    }

    private startConfig;

    /*****************/
    //*****metodos manejo cookies|sessionStorage
    public deleteClientStorage(key: string, local?: boolean) {
        if (window.localStorage) {
            local = this.getLocalStorageType(local);
            if (local) {
                localStorage.removeItem(key);
            } else {
                sessionStorage.removeItem(key);
            }
        } else {
            this.deleteCookie(key);
        }
    }

    public clearClientStorage(local?: boolean) {
        if (window.localStorage) {
            local = this.getLocalStorageType(local);
            if (local) {
                localStorage.clear();
            } else {
                sessionStorage.clear();
            }
        } else {
            this.deleteAllCookies();
        }
    }

    private getLocalStorageType(local?: boolean): boolean {
        if (local != null) {
            return local;
        } else {
            //si no se da parametro, cogemos navegador
            return navigator.appName === 'Microsoft Internet Explorer' || navigator.appVersion.indexOf('.NET4') > -1 ? false : true;
        }
    }

    private windowStorage() {
        if (navigator.appName === 'Microsoft Internet Explorer' || navigator.appVersion.indexOf('.NET4') > -1) {
            return window.localStorage;
        } else {
            return false;
        }
    }

    public putClientStorage(key: string, val: any, opts?: ICookieOpts, local?: boolean) {
        if (window.localStorage) {
            local = this.getLocalStorageType(local);
            let clean_storage = typeof val === 'object' ? JSON.stringify(val) : val;
            if (local) {
                localStorage.setItem(key, clean_storage);
            } else {
                sessionStorage.setItem(key, clean_storage);
            }
        } else {
            if (typeof val === 'string') {
                this.putExpireCookieString(key, val, opts);
            } else {
                this.putExpireCookieObject(key, val, opts);
            }
        }
    }

    public getClientStorage(key: string, obj?: boolean, local?: boolean): any {
        var cs = null;
        if (window.localStorage) {
            local = this.getLocalStorageType(local);
            if (local) {
                cs = this.cleanValueObject(localStorage.getItem(key));
            } else {
                cs = this.cleanValueObject(sessionStorage.getItem(key));
            }
        } else {
            obj = obj != null ? obj : false;
            cs = this.getCookie(key);
        }
        return this.isset(cs) ? cs : null;
    }

    public addToEntityChanges(entityType: number) {
        if (window.localStorage) {
            let key = CookieKeys.entityChanges;
            let entityTypes: any[] = [];

            if (!this.isset(this.getClientStorage(key))) {
                entityTypes.push(entityType);
                this.putClientStorage(key, entityTypes);
            } else {
                let existsEntity: boolean =
                    this.getClientStorage(key).filter((entityTypeStorage) => entityTypeStorage === entityType).length > 0 ? true : false;
                if (!existsEntity) {
                    entityTypes.push(entityType);
                    this.putClientStorage(key, entityTypes);
                }
            }
        }
    }

    public deleteEntityChanges(entityType: number) {
        if (window.localStorage) {
            let key = CookieKeys.entityChanges;
            let entityTypes: any[] = [];

            if (this.isset(this.getClientStorage(key))) {
                entityTypes = this.getClientStorage(key);
                let indexEntityTypeStorage: number = entityTypes.findIndex((entityTypeStorage) => entityTypeStorage === entityType);

                if (indexEntityTypeStorage > -1) {
                    entityTypes.splice(indexEntityTypeStorage, 1);
                    this.putClientStorage(key, entityTypes);
                }
            }
        }
    }

    public addToEntityDetectChanges(entityType: EntityType, objectId: number) {
        if (window.localStorage) {
            let key = CookieKeys.entityChanges;
            let entityTypes: IChangeTabDetect[] = [];

            if (!this.isset(this.getClientStorage(key))) {
                entityTypes.push({
                    entityType,
                    objectId,
                });
                this.putClientStorage(key, entityTypes);
            } else {
                let existsEntity: boolean =
                    this.getClientStorage(key).filter(
                        (entityTypeStorage: IChangeTabDetect) =>
                            entityTypeStorage.entityType === entityType && entityTypeStorage.objectId === objectId
                    ).length > 0
                        ? true
                        : false;
                if (!existsEntity) {
                    entityTypes = this.getClientStorage(key);
                    entityTypes.push({
                        entityType,
                        objectId,
                    });
                    this.putClientStorage(key, entityTypes);
                }
            }
        }
    }

    public deleteEntityTypeDetectChange(entityType: number, objectId?: number) {
        if (window.localStorage) {
            let key = CookieKeys.entityChanges;
            let entityTypes: IChangeTabDetect[] = [];

            if (this.isset(this.getClientStorage(key))) {
                entityTypes = this.getClientStorage(key);

                let indexEntityTypeStorage: number;
                if (this.isset(objectId)) {
                    indexEntityTypeStorage = entityTypes.findIndex(
                        (entityTypeStorage: IChangeTabDetect) =>
                            entityTypeStorage.entityType === entityType && entityTypeStorage.objectId === objectId
                    );
                } else {
                    indexEntityTypeStorage = entityTypes.findIndex(
                        (entityTypeStorage: IChangeTabDetect) => entityTypeStorage.entityType === entityType
                    );
                }

                if (indexEntityTypeStorage > -1) {
                    entityTypes.splice(indexEntityTypeStorage, 1);
                    this.putClientStorage(key, entityTypes);
                }
            }
        }
    }

    public isset(val): boolean {
        const exist = val !== null && typeof val !== 'undefined';
        return exist;
    }

    /**
     *
     * @description Get if exist or not item
     * @template T
     * @param {(boolean | string | number | null | T[])} item
     * @returns {*}  {boolean}
     */
    public isExists<T>(item: boolean | string | number | null | T[]): boolean {
        const TYPE_OF: string = typeof item;

        if (item === undefined || item === null) {
            return false;
        }

        switch (TYPE_OF) {
            case 'boolean':
                const ITEM_BOOLEAN: boolean = item as boolean;
                return ITEM_BOOLEAN === true;
            case 'string':
                const ITEM_TEXT: string = item as string;
                return ITEM_TEXT.length > 0;
            case 'number':
                const ITEM_NUMBER: number = Number(item.toString());

                if (isNaN(ITEM_NUMBER)) {
                    return false;
                }

                return ITEM_NUMBER <= 0;
            case 'object':
                if (Array.isArray(item)) {
                    const ITEM_LIST: T[] = item as T[];
                    return ITEM_LIST.length > 0;
                }
                break;
            default:
                break;
        }

        return false;
    }

    public putExpireCookieObject(key: string, value: any, opts?: ICookieOpts) {
        let expire = new Date();
        opts = opts != null ? opts : this.getCookieOpts();
        expire.setHours(expire.getHours() + opts.hours); //horas validez cookie

        this.putCookie(key, value, expire.toUTCString());
    }

    public putExpireCookieString(key: string, value: string, opts?: ICookieOpts) {
        let expire = new Date();
        opts = opts != null ? opts : this.getCookieOpts();
        expire.setHours(expire.getHours() + opts.hours); //horas validez cookie

        this.putCookie(key, value, expire.toString());
    }
    /****************/
    //**********utilidades

    public validateObjectProperties(obj: any, props: string[]) {
        let result = {
            valid: true,
            props: props,
        };

        var anyInvalid: boolean;

        if (anyInvalid !== true) {
            for (let prop of props) {
                let treeProp = prop.split('.');
                let parent_prop = treeProp[0];
                if (!this.isset(obj[parent_prop]) || (typeof obj[parent_prop] === 'string' && obj[parent_prop].length < 1)) {
                    result.valid = false;
                    anyInvalid = true;
                    break;
                } else if (treeProp.length > 1) {
                    let childsProp = prop.substring(prop.indexOf('.') + 1);
                    if (!this.validateObjectProperties(obj[parent_prop], [childsProp])) {
                        result.valid = false;
                        anyInvalid = true;
                        break;
                    }
                }
            }
        }
        return result;
    }

    public isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }
        return true;
    }

    public IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    public cleanValueObject(rst) {
        let result = rst;
        try {
            result = JSON.parse(rst);
        } catch (e) {}
        return result;
    }

    public getBasicComboItemFromEnum(e: any): BasicComboItem[] {
        var combo: BasicComboItem[] = [];
        let inver = false;
        let keys = Object.keys(e); //en caso de enum {ok = 1,warning = 2,error = 3} vienen el doble de elementos en Object.keys

        for (let k in keys) {
            if (!isNaN(Number(keys[k]))) {
                inver = true;
                break;
            }
        }
        keys.map((key) => {
            if (inver) {
                if (!isNaN(Number(key))) {
                    combo.push({ display: e[key], id: key, show: true });
                }
            } else {
                combo.push({ display: key, id: e[key], show: true });
            }
        });
        return combo.filter((options) => options.display.toLowerCase() !== 'solved');
    }

    public getTableFromEntity(mod: EntityType): string {
        let rst: string = '';

        switch (mod) {
            case EntityType.maintenanceReservation:
            case EntityType.reservation:
                rst = ActivityTab.Reservation;
                break;
            case EntityType.crew:
                rst = ActivityTab.Crew;
                break;
            case EntityType.project:
                rst = ActivityTab.Project;
                break;
            case EntityType.general_provider_tariff:
                rst = ActivityTab.GenericTariff;
                break;
            case EntityType.client_provider_tariff:
                rst = ActivityTab.CustomerTariff;
                break;
            case EntityType.person:
                rst = ActivityTab.Person;
                break;
            case EntityType.pricelist:
                rst = ActivityTab.Pricelist;
                break;
            case EntityType.quotation:
                rst = ActivityTab.Quotation;
                break;
            case EntityType.voyage:
                rst = ActivityTab.Voyage;
                break;
            case EntityType.provider:
                rst = ActivityTab.Provider;
                break;
            case EntityType.project:
                rst = ActivityTab.Project;
                break;
            case EntityType.voyage:
                rst = ActivityTab.Voyage;
                break;
            default:
                break;
        }
        return rst;
    }

    public getUriBaseFromEntity(mod: EntityType): string {
        let uri: string = '';
        switch (mod) {
            case EntityType.client:
                uri = Global.BASE_CLIENTE_CSG_ENDPOINT;
                break;
            case EntityType.project:
                uri = Global.BASE_PROYECTO_ENDPOINT;
                break;
            case EntityType.country:
                uri = Global.BASE_API_URL_PAISES;
                break;
            case EntityType.crew:
                uri = Global.BASE_TRIPULANTE_ENDPOINT;
                break;
            case EntityType.person:
                uri = Global.BASE_PERSONA_ENDPOINT;
                break;
            case EntityType.vessel:
                uri = Global.BASE_BARCO_ENDPOINT;
                break;
            case EntityType.voyage:
                uri = Global.BASE_ESCALA_ENDPOINT;
                break;
            case EntityType.port:
                uri = Global.BASE_API_URL_PUERTOS;
                break;
            case EntityType.provider:
                uri = Global.BASE_PROVEEDOR_ENDPOINT;
                break;
            case EntityType.custprov_grm:
                uri = Global.BASES_API_URL_CLIENTE_PROVEEDOR.GetByBranchName;
                break;
            case EntityType.custprov_vat_grm:
                uri = Global.BASES_API_URL_CLIENTE_PROVEEDOR.GetByVat;
                break;
            case EntityType.custprov_fiscal_grm:
                uri = Global.BASES_API_URL_CLIENTE_PROVEEDOR.GetByFiscalName;
                break;
            case EntityType.custprov_branch_grm:
                uri = Global.BASES_API_URL_CLIENTE_PROVEEDOR.GetByBranchAndFiscalName;
                break;
            case EntityType.custprov_vat_rome_code:
                uri = Global.BASES_API_URL_CLIENTE_PROVEEDOR.GetByVatAndEntityCode;
                break;
            case EntityType.custprov_name_fical_name:
                uri = Global.BASES_API_URL_CLIENTE_PROVEEDOR.GetByBranchAndFiscalName;
                break;
            case EntityType.division:
                uri = Global.BASE_API_URL_DIVISIONES;
                break;
            case EntityType.flight:
                uri = Global.BASE_VUELO_ENDPOINT;
                break;
            case EntityType.service:
                uri = Global.BASE_SERVICIO_ENDPOINT;
                break;
            case EntityType.type_service:
                uri = Global.BASE_TIPOSERVICIO_ENDPOINT;
                break;
            case EntityType.maintenanceReservation:
            case EntityType.reservation:
                uri = Global.BASE_RESERVA_ENDPOINT;
                break;
            case EntityType.reservation_service:
                uri = Global.BASE_SERVICIO_RESERVADO_ENDPOINT;
                break;
            case EntityType.quotation:
                uri = Global.BASE_COTIZACION_ENDPOINT;
                break;
            case EntityType.pricelist:
                uri = Global.BASE_PRICELIST_ENDPOINT;
                break;
            case EntityType.activity:
                uri = Global.BASE_ACTIVIDAD_ENDPOINT;
                break;
            case EntityType.type_document_rome:
                uri = Global.BASE_ROME_TIPOS_DOCUMENTOS_ENDPOINT;
                break;
            case EntityType.type_document:
                uri = Global.BASE_TIPOS_DOCUMENTOS_ENDPOINT;
                break;
            case EntityType.notification:
                uri = Global.BASE_NOTIFICACIONES_ENDPOINT;
                break;
            case EntityType.stock:
                uri = Global.BASE_INVENTARIO_ENDPOINT;
                break;
            case EntityType.rental:
                uri = Global.BASE_ALQUILER_ENDPOINT;
                break;
            case EntityType.address:
                uri = Global.BASE_DIRECCIONES_ENDPOINT;
                break;
            case EntityType.incidence:
                uri = Global.BASE_INCIDENCIAS_ENDPOINT;
                break;
            case EntityType.loginProvider:
            case EntityType.driver:
                uri = Global.BASE_API_URL_USUARIOSBYCODE;
                break;
            case EntityType.loginDelegatedProvider:
                uri = Global.BASE_API_URL_USER_BY_GROUP_CODE_LIST;
                break;
            case EntityType.user_grm:
                uri = Global.BASE_API_URL_USUARIOS;
                break;
            case EntityType.reference:
                uri = Global.BASE_REFERNCE_CONTROLLER_ENDPOINT;
                break;
            case EntityType.currency:
                uri = Global.BASE_DIVISAS_ENDPOINT;
                break;
            case EntityType.group:
                uri = Global.BASE_GRUPO_ENDPOINT;
                break;
            case EntityType.reservationExtraService:
                uri = Global.BASE_SERVICIO_GENERICO_PROVEEDOR_ENDPOINT;
                break;
            case EntityType.categories:
                uri = Global.BASE_PROJECT_CATEGORY_ENDPOINT;
                break;
            case EntityType.customer:
                uri = Global.BASE_CLIENTE_ENDPOINT;
                break;
            case EntityType.line:
                uri = Global.BASE_LINE_ENDPOINT;
                break;
            case EntityType.providerDriver:
                uri = Global.DRIVER_BASE_ENDPOINT;
                break;
            case EntityType.agent:
                uri = Global.CUSTOMER_PROVIDER_BASE_ENDPOINT;
                break;
            default:
                uri = Global.BASE_PROYECTO_ENDPOINT;
                break;
        }
        return uri;
    }

    public getSafeEndpoint(uri: string) {
        let base_url = Global.DEVELOP_MODE ? Global.BASE_API_URL_DEVELOP : Global.BASE_API_URL_PROD;

        if (uri[0] === '/') {
            return base_url + uri;
        }

        if (uri.indexOf('http') === -1) {
            if (Global.DEVELOP_MODE) {
                return uri.indexOf(Global.BASE_API_URL_DEVELOP) > -1 ? uri : base_url + '/' + uri;
            } else {
                return uri.indexOf(Global.BASE_API_URL_PROD) > -1 ? uri : base_url + '/' + uri;
            }
        } else {
            return uri;
        }
    }

    //esta funcion actualiza el token de validacion de forma asincrona
    public checkTimeToken(force?: boolean) {
        force = force != null ? force : false;
        if (this.router.url.indexOf('login') === -1 || force) {
            let logging_cookie = this.getClientStorage(CookieKeys.login, true, true);
            if ((logging_cookie != null && this.isset(logging_cookie['Datetime'])) || force) {
                let dt: Date = logging_cookie != null && this.isset(logging_cookie['Datetime']) ? logging_cookie['Datetime'] : null;
                var minDiff = 1000;
                if (dt != null) {
                    let naw = new Date().getTime();
                    let ini = new Date(dt).getTime();
                    let milisec = naw - ini;
                    minDiff = milisec / 60 / 1000; //in minutes
                }
                if (minDiff > 20 || force) {
                    //hay que renovar token,
                    //hacemos un relogin hasta que sepamos como usar el metodo PATCH del GRM
                    logging_cookie['Datetime'] = new Date();
                    this.putClientStorage(CookieKeys.login, logging_cookie, { hours: 360 }, true);
                    delete logging_cookie['Datetime'];
                    if (force) {
                        if (!this.just_logged) {
                            this.just_logged = true;
                            this._http.post(Global.BASE_API_URL_USER_VALIDATION, logging_cookie).subscribe(
                                (resp: Response) => {
                                    let rst = resp.json();
                                    if (this.isset(resp['status']) && resp['status'] === 200 && typeof rst === 'string') {
                                        this.just_logged = false;
                                        this.setToken(rst); //establecemos token de validacion
                                    }
                                },
                                (error) => {}
                            );
                        } else {
                            this.just_logged = false;
                        }
                    } else {
                        let tokn = this.getToken();
                        this._http
                            .patch(Global.BASE_API_URL_USER_VALIDATION, '') //
                            .subscribe(
                                (resp: Response) => {},
                                (error) => {}
                            );
                    }
                }
            }
        }
    }

    getEntityTypeFromField(cols: any[], field: string): EntityType {
        let val: EntityType = 1;
        let found = false;
        for (let i in cols) {
            let aux = cols[i];
            for (let k in aux) {
                if (k === field && typeof aux['Entity'] !== 'undefined') {
                    val = aux['Entity'];
                    found = true;
                    break;
                }
            }
            if (found) {
                break;
            }
        }
        return val;
    }
    //**********transformación de datos
    public formatRetrievedData(preform: any[], typo?: string, time?: boolean, separator?: string, child?: boolean) {
        child = child != null ? child : false;
        time = time != null ? time : false;
        typo = typo != null ? typo : 'bbdd';
        let fake_array = false;
        let aux = [];
        if (this.isset(preform['Data'])) {
            aux = preform['Data'];
        } else {
            aux = preform;
        }

        if (!this.isset(aux[0])) {
            fake_array = true;
            aux = [aux];
        }

        let result = aux;
        for (var ind in aux) {
            let element = aux[ind];
            for (var field in element) {
                let interm = element[field];
                if (interm != null) {
                    if (field.indexOf('Date') > -1) {
                        //para campos fecha, formateamos
                        result[ind][field] = this.getFormattedDate(interm, typo, time, separator);
                    } else if (interm instanceof Array) {
                        if (this.isset(interm[0])) {
                            result[ind][field] = this.formatRetrievedData(interm, typo, time, separator, true);
                        } else if (!this.isEmpty(interm)) {
                            result[ind][field] = this.formatRetrievedData([interm], typo, time, separator, true);
                        }
                    } else if (interm instanceof Object) {
                        result[ind][field] = this.formatRetrievedData(interm, typo, time, separator, true);
                    }
                }
            }
        }

        if (this.isset(preform['Data'])) {
            preform['Data'] = result;
        } else {
            preform = result;
        }

        if (fake_array && child) {
            preform = preform[0];
        }

        return preform;
    }
    public formatBulkData(mod: any, typo?: string, time?: boolean, separator?: string): any {
        var aux = mod;
        for (let field in aux) {
            if (aux[field] == null || (field === 'ID' && aux[field] === '')) {
                //el que venga nulo, lo eliminamos
                delete mod[field];
            } else if (field.indexOf('Date') > -1) {
                //nos viene un campo fecha como objeto datepicker
                if (this.isset(aux[field]['jsdate'])) {
                    mod[field] = this.getFormattedDate(aux[field]['jsdate'], 'bbdd');
                } else {
                    mod[field] = this.getFormattedDate(aux[field], 'bbdd', time, separator);
                }
            } else if (aux[field] instanceof Array) {
                if (this.isset(aux[field][0])) {
                    for (let ind in aux[field]) {
                        if (field.indexOf('SignerFlightList') > -1) {
                            mod[field][ind] = this.formatBulkData(aux[field][ind], typo, true, separator);
                        } else {
                            mod[field][ind] = this.formatBulkData(aux[field][ind], typo, time, separator);
                        }
                    }
                } else {
                    mod[field] = this.formatBulkData(aux[field], typo, time, separator);
                }
            } else if (aux[field] instanceof Object) {
                mod[field] = this.formatBulkData(aux[field], typo, time, separator);
            }
        }
        return mod;
    }

    /*DEFAULT CONFIGS*/
    public getCookieOpts(h?, p?, ht?, st?, d?, s?): ICookieOpts {
        h = h != null ? h : 1;
        p = p != null ? p : window.location.hostname;
        ht = ht != null ? ht : false;
        st = st != null ? st : false;
        d = d != null ? d : document.domain;
        s = s != null ? s : false;
        return { hours: h, path: p, httpOnly: ht, storeUnencoded: st, domain: d, secure: s };
    }

    public getDefaultDataTableParams(n?: number, ide?: number): IDataTableJsParam {
        n = n != null ? n : Global.LIMIT_DEFAULT_PAGEDITEMS;
        ide = ide != null ? ide : 0;
        return {
            showdeleted: false,
            id: ide,
            order: [
                {
                    column: 0,
                    dir: DataTableJsColumnOrderDirection.desc,
                },
            ],
            length: n,
            start: 0,
            columns: [
                {
                    operation: DataTableJsColumnOperation.contains,
                    data: '',
                    name: 'ID',
                    searchable: false,
                    orderable: true,
                    options: [],
                },
            ],
        };
    }

    public getDefaultInfoTab(): InfoTab {
        return {
            id_breadcum: 0,
            tmp: null,
            wrappedtype: null,
            breadcum: null,
            path: null,
            title: null,
            icon: null,
            class: null,
            targetchild: null,
            field: null,
            entity: null,
        };
    }

    private getRequestOptions(typo: DBOperation, tkn?: string): RequestOptions {
        let bearer = tkn != null && tkn.length > 1 ? tkn : this.getBearer();
        let reqObjects = {};
        switch (typo) {
            case DBOperation.select:
            case DBOperation.view:
                {
                    reqObjects = {
                        headers: new Headers({
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + bearer,
                        }),
                    };
                }
                break;
            case DBOperation.patch:
                {
                    reqObjects = {
                        headers: new Headers({
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + bearer,
                        }),
                    };
                }
                break;
            default:
                {
                    reqObjects = {
                        headers: new Headers({
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + bearer,
                        }),
                    };
                }
                break;
        }
        let opts: RequestOptions = new RequestOptions(reqObjects);
        return opts;
    }

    private getRequestOptionsHttpClient(typo: DBOperation, tkn?: string): any {
        let bearer = tkn != null && tkn.length > 1 ? tkn : this.getBearer();
        let reqObjects = {};
        switch (typo) {
            case DBOperation.select:
            case DBOperation.view:
                {
                    reqObjects = {
                        headers: new HttpHeaders({
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + bearer,
                        }),
                    };
                }
                break;
            case DBOperation.patch:
                {
                    reqObjects = {
                        headers: new HttpHeaders({
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + bearer,
                        }),
                    };
                }
                break;
            default:
                {
                    reqObjects = {
                        headers: new HttpHeaders({
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + bearer,
                        }),
                    };
                }
                break;
        }
        //let opts: RequestOptions = new RequestOptions(reqObjects);
        return reqObjects;
    }

    public getDefaultRequestOptions(typo: DBOperation, check_tokentime?: boolean): any {
        check_tokentime = check_tokentime != null ? check_tokentime : true;
        if (check_tokentime) {
            this.checkTimeToken();
        }
        let tokn = this.getToken();
        return this.getRequestOptions(typo, tokn);
    }

    public getDefaultRequestOptionsHttpClient(typo: DBOperation, check_tokentime?: boolean): any {
        check_tokentime = check_tokentime != null ? check_tokentime : true;
        if (check_tokentime) {
            this.checkTimeToken();
        }
        let tokn = this.getToken();
        return this.getRequestOptionsHttpClient(typo, tokn);
    }

    public getMessageError(error: Response): string {
        if (this.IsJsonString(error['_body'])) {
            //Si no hay contenido json en parametro _body,el metodo .json() produce casque
            let _body = error.json();
            //de toda las propiedades que tiene error(status, _body, statusText, etc), el.json() saca el '_body'
            if (this.isset(_body.error)) {
                return _body.error;
            } else if (this.isset(_body.Message)) {
                if (this.isset(_body.InnerException)) {
                    if (this.isset(_body.InnerException.ExceptionMessage)) {
                        return _body.InnerException.ExceptionMessage;
                    } else {
                        return _body.InnerException.Message;
                    }
                } else if (this.isset(_body.ExceptionMessage)) {
                    return _body.ExceptionMessage;
                } else {
                    return _body.Message;
                }
            } else {
                return this.HandleInternalServerError(_body);
            }
        } else {
            if (this.isset(error['status'])) {
                if (error['status'] === 401) {
                    if (this.isset(error['statusText'])) {
                        if (error['statusText'].indexOf('Unauthorized') > -1) {
                            this.checkTimeToken(true); //forzamos actualizacion de token
                            return 'NOTIFICACION.DEPRECATED_TOKEN';
                        } else {
                            return error['statusText'];
                        }
                    } else {
                        return 'Server Error ' + error['status'];
                    }
                } else {
                    return 'Server Error ' + error['status'];
                }
            } else {
                return 'Server Error';
            }
        }
    }

    public getMessageErrorHttpClient(error: any): string {
        if (typeof error === 'string') {
            return error;
        }

        if (this.isset(error['error'])) {
            if (this.isset(error['status'])) {
                if (error['status'] === 401) {
                    return 'NOTIFICACION.DEPRECATED_TOKEN';
                } else {
                    if (this.isset(error['error']['UserMessages']) && error['error']['UserMessages'].length > 0) {
                        return error['error']['UserMessages'][0]['Message'];
                    } else {
                        return 'Server Error ' + error['status'];
                    }
                }
            }
        }

        if (this.IsJsonString(error['_body'])) {
            let _body = error.json();
            if (this.isset(_body.error)) {
                return _body.error;
            } else if (this.isset(_body.Message)) {
                if (this.isset(_body.InnerException)) {
                    if (this.isset(_body.InnerException.ExceptionMessage)) {
                        return _body.InnerException.ExceptionMessage;
                    } else {
                        return _body.InnerException.Message;
                    }
                } else if (this.isset(_body.ExceptionMessage)) {
                    return _body.ExceptionMessage;
                } else {
                    return _body.Message;
                }
            } else {
                return this.HandleInternalServerError(_body);
            }
        } else {
            if (this.isset(error['status'])) {
                if (error['status'] === 401) {
                    if (this.isset(error['statusText'])) {
                        if (error['statusText'].indexOf('Unauthorized') > -1) {
                            this.checkTimeToken(true);
                            return 'NOTIFICACION.DEPRECATED_TOKEN';
                        } else {
                            return error['statusText'];
                        }
                    } else {
                        return 'Server Error ' + error['status'];
                    }
                } else {
                    return 'Server Error ' + error['status'];
                }
            } else {
                return 'Server Error';
            }
        }
    }

    private HandleInternalServerError(body: any): string {
        var defaultMessage = 'Server Error';
        try {
            if (!body || !body.UserMessages) {
                return defaultMessage;
            }

            var errorMessage = null;
            body.UserMessages.forEach((message) => {
                if (message.Message && !errorMessage) {
                    errorMessage = message.Message;
                }
            });
            return (errorMessage || defaultMessage).trim();
        } catch (err) {
            return defaultMessage;
        }
    }

    public getResponse(rsp: any, first_one?: boolean, format?: boolean, typo?: string, time?: boolean, separator?: string): any {
        format = format != null ? format : false;
        time = time != null ? time : false;
        first_one = first_one != null ? first_one : false;
        typo = typo != null ? typo : 'bbdd';
        try {
            let v = rsp.json();
            if (
                this.isset(v['Messages']) &&
                this.isset(v['Messages'][0]) &&
                this.isset(v['Messages'][0]['Message']) &&
                v['Messages'][0]['Message'].indexOf('Operation performed correctly.') === -1
            ) {
                let msg_str: string = '';
                for (let msg_object of v['Messages']) {
                    msg_str += msg_object['Message'] + ' ';
                }
                return msg_str;
            } else if (this.isset(v['Data'])) {
                let data = v['Data'];
                if (this.isset(v['Data'][0])) {
                    if (v['Data'][0] !== false) {
                        if (first_one) {
                            data = v['Data'][0];
                        }

                        var rst = format ? this.formatRetrievedData(data, typo, time, separator) : data;
                        if (first_one && this.isset(rst[0])) {
                            rst = rst[0];
                        }

                        return rst;
                    } else {
                        return 'NOTIFICACION.PROBLEMA';
                    }
                } else {
                    return data;
                }
            } else {
                if (rsp.status === 200) {
                    return typeof rsp === 'object' && this.isset(rsp['_body']) ? rsp['_body'] : rsp;
                } else {
                    return 'NOTIFICACION.PROBLEMA';
                }
            }
        } catch (e) {
            if (rsp.status === 200) {
                return typeof rsp === 'object' && this.isset(rsp['_body']) ? rsp['_body'] : rsp;
            } else {
                return 'NOTIFICACION.PROBLEMA';
            }
        }
    }

    public getResponseHttpClient(response: any) {
        if (response !== null) {
            return this.isset(response.Data) ? response.Data : response;
        }
        return [];
    }

    public getEResponse(result: any): boolean {
        result = this.isset(result['Data']) ? result['Data'] : result;
        return (this.isset(result[0]) && (result[0] === true || result instanceof Object)) || !isNaN(result) ? true : false;
    }

    public getLocaleDateTimeConfig(locale: string): any {
        let config: any;
        switch (locale) {
            case 'en':
                config = {
                    firstDayOfWeek: 0,
                    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                    monthNames: [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                    ],
                    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                };
                break;
            default:
                config = {
                    firstDayOfWeek: 1,
                    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
                    monthNames: [
                        'Enero',
                        'Febrero',
                        'Marzo',
                        'Abril',
                        'Mayo',
                        'Junio',
                        'Julio',
                        'Agosto',
                        'Septiembre',
                        'Octubre',
                        'Noviembre',
                        'Diciembre',
                    ],
                    monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                };
                break;
        }
        return config;
    }

    public getDateUTC(dateTimeUTC: string) {
        return new Date(new Date(Date.parse(dateTimeUTC)).toUTCString().substr(0, 25));
    }

    sortByPropertyString(list: any[], property: string, order?: string): any[] {
        if (!this.isset(order)) {
            return this.ascSortByPropertyString(list, property);
        } else {
            if (order === 'ASC') {
                return this.ascSortByPropertyString(list, property);
            } else if (order === 'DESC') {
                return this.descSortByPropertyString(list, property);
            }
        }
    }

    ascSortByPropertyString(list: any[], property: string): any[] {
        return list.sort(function (a, b) {
            var x = a[property] === null ? '' : a[property].toString().toLowerCase();
            var y = b[property] === null ? '' : b[property].toString().toLowerCase();
            if (x > y) {
                return -1;
            }
            if (x < y) {
                return 1;
            }
            return 0;
        });
    }

    descSortByPropertyString(list: any[], property: string): any[] {
        return list.sort(function (a, b) {
            var x = a[property] === null ? '' : a[property].toString().toLowerCase();
            var y = b[property] === null ? '' : b[property].toString().toLowerCase();
            if (x < y) {
                return -1;
            }
            if (x > y) {
                return 1;
            }
            return 0;
        });
    }

    sortByPropertyNumber(list: any[], property: string): any[] {
        return list.sort(function (anyOne, anyTwo): any {
            return anyOne[property] - anyTwo[property];
        });
    }

    sortByPropertyNegativeNumber(list: any[], property: string): any[] {
        return list.sort((a, b) => {
            return a - b;
        });
    }

    public translateWithParams(tagTranslate: string, objectParams: any, translateService: TranslateService): string {
        return translateService.instant(tagTranslate, objectParams);
    }

    public cancellingDragAndDrop() {
        $(document.body).bind('dragover', function (e) {
            e.preventDefault();
            return false;
        });

        $(document.body).bind('drop', function (e) {
            e.preventDefault();
            return false;
        });
    }

    public previousConditions(paramsFilter: IDataTableJsParam, conditions: DataTableJsColumn[]): DataTableJsColumn[] {
        let columns = paramsFilter.columns.filter((column) => column.name !== 'ID');
        let previousConditions: DataTableJsColumn[] = [];
        let existsFilters: boolean = false;
        if (this.isset(paramsFilter.columns) && paramsFilter.columns.length > 0) {
            for (let column of columns) {
                if (column.data.length > 0) {
                    previousConditions.push(column);
                    existsFilters = true;
                }
                column.searchable = false;
            }
        }

        return existsFilters ? previousConditions : conditions;
    }

    deleteDuplicateOnObject(list: any[], property: string): any {
        if (list.length > 0) {
            let unique = Array.from(new Set(list.map((a) => a[property]))).map((attribute) => {
                return list.find((a) => a[property] === attribute);
            });
            return unique;
        } else {
            return list;
        }
    }

    deleteDuplicateOnArray(list: any[]): any[] {
        return list.reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), []);
    }

    deleteDuplicateOnArrayWithProperty(list: any[], property: string): any[] {
        return list.reduce((unique, item) => (unique.find((a) => a[property] === item[property]) ? unique : [...unique, item]), []);
    }

    deleteDuplicateOnArrayWithMultipleProperties(list: any[], properties: string[]): any[] {
        return list.reduce((unique, item) => {
            const EXISTS = unique.find((a) => properties.every((property) => a[property] === item[property]));
            return EXISTS ? unique : [...unique, item];
        }, []);
    }

    loadScripts(scripts: string[]) {
        for (let script of scripts) {
            let scriptToAdd = document.createElement('script');
            scriptToAdd.type = 'text/javascript';
            document.getElementsByTagName('body')[0].appendChild(scriptToAdd);
            scriptToAdd.src = script;
        }
    }

    returnYesOrNotLabel(option: boolean, translateService: TranslateService): string {
        if (this.isset(option)) {
            return option ? translateService.instant('true') : translateService.instant('false');
        }
        return '-';
    }

    public diferenceDaysWithTwoDays(startDate: Date, endDate: Date): number {
        return moment(endDate).startOf('days').diff(moment(startDate).startOf('days'), 'days');
    }

    setDataTableTranslate(translateService: TranslateService): any {
        return {
            decimal: translateService.instant('Datatable.decimal'),
            emptyTable: translateService.instant('Datatable.emptyTable'),
            info: translateService.instant('Datatable.info'),
            infoEmpty: translateService.instant('Datatable.infoEmpty'),
            infoFiltered: translateService.instant('Datatable.infoFiltered'),
            infoPostFix: translateService.instant('Datatable.infoPostFix'),
            thousands: translateService.instant('Datatable.thousands'),
            lengthMenu: translateService.instant('Datatable.lengthMenu'),
            loadingRecords: translateService.instant('Datatable.loadingRecords'),
            processing: translateService.instant('Datatable.processing'),
            search: translateService.instant('Datatable.search'),
            zeroRecords: translateService.instant('Datatable.zeroRecords'),
            paginate: {
                first: translateService.instant('Datatable.paginate.first'),
                last: translateService.instant('Datatable.paginate.last'),
                next: translateService.instant('Datatable.paginate.next'),
                previous: translateService.instant('Datatable.paginate.previous'),
            },
            aria: {
                sortAscending: translateService.instant('Datatable.aria.sortAscending'),
                sortDescending: translateService.instant('Datatable.aria.sortDescending'),
            },
        };
    }

    setDataTableLanguage(): any {
        return {
            'decimal': '',
            'emptyTable': 'No hay datos disponibles en la tabla',
            'info': 'Ver del _START_ al _END_ de _TOTAL_ registros',
            'infoEmpty': 'Ver del 0 al 0 de 0 registros',
            'infoFiltered': '(Filtrado de _MAX_ registros totales)',
            'infoPostFix': '',
            'thousands': ',',
            'lengthMenu': 'Ver _MENU_ registros',
            'loadingRecords': 'Cargando...',
            'processing': 'Procesando...',
            'search': 'Buscar:',
            'zeroRecords': 'No se encontraron registros coincidentes',
            'paginate': {
                'first': 'Primera',
                'last': 'Ultima',
                'next': 'Siguiente',
                'previous': 'Anterior',
            },
            'aria': {
                'sortAscending': ': activar para ordenar la columna ascendente',
                'sortDescending': ': activar para ordenar la columna descendente',
            },
        };
    }

    filteredValues(value: string, objectsList: any[], filters: any[], minimunCaracters: number): any[] {
        let filteredValues: any[] = this.sortResultAutocomplete(objectsList);
        let newObjectList: any[] = [];
        if (value !== null && value !== undefined && value.length >= minimunCaracters) {
            for (const filter of filters) {
                if (typeof filter === 'object') {
                    const parent: string = filter[0];
                    for (const children of filter[1]) {
                        filteredValues = objectsList.filter((object) =>
                            object[parent][children].toString().toLowerCase().includes(value.toLowerCase())
                        );
                        newObjectList = newObjectList.length > 0 ? newObjectList.concat(filteredValues) : [].concat(filteredValues);
                    }
                } else {
                    filteredValues = objectsList.filter((object) => {
                        if (object[filter] !== null && object[filter] !== undefined && typeof object[filter] === 'string') {
                            return object[filter].toString().toLowerCase().includes(value.toLowerCase());
                        }
                    });
                    newObjectList = newObjectList.length > 0 ? newObjectList.concat(filteredValues) : [].concat(filteredValues);
                }
            }
            return newObjectList;
        }
        return [];
    }

    sortResultAutocomplete(list: any[]): any[] {
        // tslint:disable-next-line: only-arrow-functions
        return list.sort(function (anyOne, anyTwo): any {
            return anyOne.id - anyTwo.id;
        });
    }

    formatFormsValues(value: any, displayValues: string[]): string {
        if (this.isset(value)) {
            let result = '';
            for (let index = 0; index < displayValues.length; index++) {
                if (
                    value[displayValues[index]] !== undefined &&
                    value[displayValues[index]] !== null &&
                    value[displayValues[index]].toString().trim().length > 0
                ) {
                    const DISPLAY_VALUE: string = value[displayValues[index]];
                    result += index > 0 ? ' | ' + DISPLAY_VALUE : DISPLAY_VALUE;
                }
            }
            return result;
        }
        return '';
    }

    getPatternWithFourDecimalsNumber(): string {
        return '^[0-9]*([.]?[,]?[0-9]{0,4})?$';
    }

    /**
     *
     * @description Receive event date when change date on input date and manage this
     * @param {*} event
     * @param {FormGroup} form
     * @param {string} formControl
     * @returns boolean
     */
    changeDateModel(event: any, form: FormGroup, formControl: string): boolean {
        const INPUT_DATE = event.target.value;
        if (!INPUT_DATE) {
            return false;
        }
        const VALIDATE_DATE = moment(INPUT_DATE, CHANGE_DATE_FORMAT_LIST, true);
        const IS_VALID = VALIDATE_DATE.isValid();

        if (IS_VALID) {
            const formattedDate = VALIDATE_DATE.format(EDATE_FORMAT.DATABASE_WITH_TIME);
            form.controls[formControl].patchValue(formattedDate);
        } else {
            form.controls[formControl].setErrors({ incorrect: false });
        }

        return IS_VALID;
    }

    /**
     *
     * @description Receive event date when change date with hours on input date and manage this
     * @param {*} event
     * @param {FormGroup} form
     * @param {string} formControl
     * @returns boolean
     */
    changeDateModelWithHours(event: any, form: FormGroup, formControl: string) {
        const INPUT_DATE = event.target.value.trim();
        const VALIDATE_DATE = moment(INPUT_DATE, CHANGE_DATE_WITH_HOURS_FORMAT_LIST, true);
        const IS_VALID = VALIDATE_DATE.isValid();

        if (IS_VALID) {
            const FORMATTED_DATE: string = VALIDATE_DATE.format(EDATE_FORMAT.DATABASE_WITH_TIME);
            form.controls[formControl].patchValue(FORMATTED_DATE);
        } else {
            if (INPUT_DATE === '') {
                form.controls[formControl].setErrors({ incorrect: false });
                form.controls[formControl].reset();
            } else {
                form.controls[formControl].setErrors({ incorrect: true });
            }
        }
    }

    getFirstAndLastDayOnMonth(date: Date): { firstDay: string; lastDay: string } | null {
        if (this.isset(date) && date instanceof Date && !isNaN(date.getTime())) {
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            return {
                firstDay: moment(firstDay).format('MM-DD-YYYY'),
                lastDay: moment(lastDay).format('MM-DD-YYYY'),
            };
        }
        return null;
    }

    convertStringToArray(value: any): any {
        if (typeof value === 'string') {
            return [value];
        }
        return value;
    }

    getFirstAndLastDayOfWeek(date: Date): { firstDay: Date; lastDay: Date } {
        let week = new Array();
        let isSunday: boolean = date.getDay() === 0;
        const currentDate: Date = date;

        if (!isSunday) {
            currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1);
        }

        for (var i = 0; i < 7; i++) {
            week.push(new Date(date));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        // week = week.filter(day => moment(day).format('MM') === moment(currentDate).format('MM'));

        return { firstDay: week[0], lastDay: week[week.length - 1] };
    }

    eventControl(event: any) {
        event.preventDefault();
        event.stopPropagation();
    }

    getFormatToMomentWithRegex(date: any): string {
        if (new RegExp('^[0-3]?[0-9]-[0-1][0-9]-[0-9]{4} [0-2][0-9]:[0-6][0-9](:[0-9])?$').test(date)) {
            return 'DD-MM-YYYY HH:mm';
        } else if (new RegExp('^[0-9]{4}-[0-3]?[0-9]-[0-1][0-9][[]T[]][0-2][0-9]:[0-6][0-9](:[0-9])?$').test(date)) {
            return 'YYYY-MM-DD[T]HH:mm';
        } else if (new RegExp('^[0-9]{4}-[0-1][0-9]-[0-3]?[0-9][[]T[]][0-2][0-9]:[0-6][0-9](:[0-9])?$').test(date)) {
            return 'YYYY-DD-MM[T]HH:mm';
        } else if (new RegExp('^[0-9]{2}[0-9]{2}-[0-9]{2}-[0-9]{2}T[0-6][0-9]:[0-6][0-9]:[0-6][0-9]$').test(date)) {
            return 'YYYY-MM-DD[T]HH:mm:ss';
        } else if (new RegExp('^[0-9]{2}[.][0-9]{2}[.][0-9]{4}$').test(date)) {
            return 'DD.MM.YYYY';
        }

        return null;
    }

    compareDatesWithMoment(date: Date | string, isMinusDate: Date | string, method?: string): boolean {
        let transformDate: Date | string = date;
        let transformIsMinusDate: Date | string = isMinusDate;

        if (transformDate !== null && transformIsMinusDate !== null) {
            transformDate =
                typeof transformDate === 'object'
                    ? moment(new Date(transformDate)).format('YYYY-MM-DD')
                    : moment(new Date(transformDate), this.getFormatToMomentWithRegex(transformDate)).format('YYYY-MM-DD');
            transformIsMinusDate =
                typeof transformIsMinusDate === 'object'
                    ? moment(new Date(transformIsMinusDate)).format('YYYY-MM-DD')
                    : moment(new Date(transformIsMinusDate), this.getFormatToMomentWithRegex(transformIsMinusDate)).format('YYYY-MM-DD');

            if (transformDate === transformIsMinusDate) {
                return this.compareHoursWithMoment(date, isMinusDate);
            } else {
                if (transformDate.split('-')[0] > transformIsMinusDate.split('-')[0]) {
                    return true;
                } else if (
                    transformDate.split('-')[1] > transformIsMinusDate.split('-')[1] &&
                    transformDate.split('-')[0] === transformIsMinusDate.split('-')[0]
                ) {
                    return true;
                } else if (
                    transformDate.split('-')[2] > transformIsMinusDate.split('-')[2] &&
                    transformDate.split('-')[1] === transformIsMinusDate.split('-')[1] &&
                    transformDate.split('-')[0] === transformIsMinusDate.split('-')[0]
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return false;
    }

    compareHoursWithMoment(date: Date | string, isMinusDate: Date | string, method?: string): boolean {
        if (date !== null && isMinusDate !== null) {
            date =
                typeof date === 'object'
                    ? moment(new Date(date)).format('HH:mm')
                    : moment(new Date(date), this.getFormatToMomentWithRegex(date)).format('HH:mm');
            isMinusDate =
                typeof isMinusDate === 'object'
                    ? moment(new Date(isMinusDate)).format('HH:mm')
                    : moment(new Date(isMinusDate), this.getFormatToMomentWithRegex(isMinusDate)).format('HH:mm');

            if (date.split(':')[0] === isMinusDate.split(':')[0]) {
                if (date.split(':')[1] > isMinusDate.split(':')[1]) {
                    return true;
                }
            } else {
                if (date.split(':')[0] > isMinusDate.split(':')[0]) {
                    return true;
                }
            }

            return false;
        }
        return false;
    }

    /**
     * @description Add minutes to adate using moment js
     * @param  {Date|string} date
     * @param  {number} minutes
     */
    addMinutesToDate(date: Date | string, minutes: number): Date {
        return moment(date).add(minutes, 'm').toDate();
    }

    sumAllValuesForAttribureOnList(list: any[], attribute: string[]) {
        let total: number = 0;
        for (const item of list) {
            if (item[attribute[0]][attribute[1]] !== null) {
                total = total + item[attribute[0]][attribute[1]];
            }
        }

        return total;
    }

    // COOKIES
    setCookie(cname: string, cvalue: any, expire: string) {
        let d = new Date();
        // d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        let expires = 'expires=' + expire;
        const cookies: string[] = document.cookie.split(';');
        // cookies.push(cname + '=' + cvalue + ';' + expires + ';path=/');

        const setterCookies = cookies.join(';').toString();

        this.deleteAllCookies();

        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }

    getCookie(name: string) {
        let cookieArr = document.cookie.split(';');
        for (let i = 0; i < cookieArr.length; i++) {
            let cookiePair = cookieArr[i].split('=');
            if (name === cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1]);
            }
        }
        return null;
    }

    putCookie(cname: string, cvalue: any, expire: string): void {
        this.deleteCookie(cname);
        this.setCookie(cname, cvalue, expire);
    }

    deleteCookie(name: string): void {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf(name);
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    }

    deleteAllCookies(): void {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf('=');
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    }

    getLengthInput(input: any): number {
        if (input.value === null || input.value === undefined) {
            return 0;
        } else {
            return input.value.length;
        }
    }

    removeAccents(value: string): string {
        return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    cleanObject(obj: any): any {
        for (const propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
        return obj;
    }

    // ARRAY
    swapAnyOnArray(list: any[], x: number, y: number): any[] {
        const z = list[y];
        list[y] = list[x];
        list[x] = z;

        return list;
    }

    // Modal Message Component
    // DOCUMENTS

    /**
     * @description Init Modal Message Data For Prevent Pending Save Document specified
     * @param  {ModalMessages} modalMessageConfig?
     * @returns ModalMessages
     */
    initModalMessageForPreventPendingSaveDocument(modalMessageConfig?: ModalMessages): ModalMessages {
        if (!this.isset(modalMessageConfig)) {
            return {
                title: 'DOCUMENTOS.MODEL',
                messages: [{ message: 'DOCUMENTOS.MENSAJES.PENDING_CHANGES', messageType: ModalMessageType.warning }],
                translateMessages: true,
                closeButtonShow: false,
                actionButtonShow: false,
                closeButtonLabel: 'BTNS.ACCEPT',
                closeButtonTitle: 'BTNS.ACCEPT',

                auxiliarMessage: null,
                auxiliarMessageType: null,
                actionButtonOption: undefined,
            };
        } else {
            return {
                title: this.isset(modalMessageConfig.title) ? modalMessageConfig.title : 'DOCUMENTOS.MODEL',
                messages:
                    this.isset(modalMessageConfig.messages) && modalMessageConfig.messages.length > 0
                        ? modalMessageConfig.messages
                        : [{ message: 'DOCUMENTOS.MENSAJES.PENDING_CHANGES', messageType: ModalMessageType.warning }],
                translateMessages: this.isset(modalMessageConfig.translateMessages) ? modalMessageConfig.translateMessages : true,
                closeButtonShow: this.isset(modalMessageConfig.closeButtonShow) ? modalMessageConfig.closeButtonShow : true,
                actionButtonShow: this.isset(modalMessageConfig.actionButtonShow) ? modalMessageConfig.actionButtonShow : true,
                closeButtonLabel: this.isset(modalMessageConfig.closeButtonLabel) ? modalMessageConfig.closeButtonLabel : 'BTNS.ACCEPT',
                closeButtonTitle: this.isset(modalMessageConfig.closeButtonTitle) ? modalMessageConfig.closeButtonTitle : 'BTNS.ACCEPT',
                actionButtonLabel: this.isset(modalMessageConfig.actionButtonLabel) ? modalMessageConfig.actionButtonLabel : 'BTNS.KEEP',
                actionButtonTitle: this.isset(modalMessageConfig.actionButtonTitle) ? modalMessageConfig.actionButtonTitle : 'BTNS.KEEP',
                auxiliarMessage: this.isset(modalMessageConfig.auxiliarMessage) ? modalMessageConfig.auxiliarMessage : null,
                actionButtonOption: this.isset(modalMessageConfig.actionButtonOption) ? modalMessageConfig.actionButtonOption : undefined,
                item: this.isset(modalMessageConfig.item) ? modalMessageConfig.item : null,
            };
        }
    }

    /**
     * @description Init Modal Message Data
     * @param  {ModalMessages} modalMessageConfig?
     */
    initModalMessageData(modalMessageConfig?: ModalMessages): ModalMessages {
        if (!this.isset(modalMessageConfig)) {
            return {
                title: '',
                messages: [],
                translateMessages: true,
                closeButtonShow: true,
                actionButtonShow: true,
                closeButtonLabel: 'BTNS.ACCEPT',
                closeButtonTitle: 'BTNS.ACCEPT',
                actionButtonLabel: 'BTNS.CANCELAR',
                actionButtonTitle: 'BTNS.CANCELAR',
                auxiliarMessage: null,
                auxiliarMessageType: null,
                actionButtonOption: undefined,
            };
        } else {
            return {
                title: this.isset(modalMessageConfig.title) ? modalMessageConfig.title : '',
                messages: this.isset(modalMessageConfig.messages) && modalMessageConfig.messages.length > 0 ? modalMessageConfig.messages : [],
                translateMessages: this.isset(modalMessageConfig.translateMessages) ? modalMessageConfig.translateMessages : true,
                closeButtonShow: this.isset(modalMessageConfig.closeButtonShow) ? modalMessageConfig.closeButtonShow : true,
                actionButtonShow: this.isset(modalMessageConfig.actionButtonShow) ? modalMessageConfig.actionButtonShow : true,
                actionButtonLabel: this.isset(modalMessageConfig.actionButtonLabel) ? modalMessageConfig.actionButtonLabel : 'BTNS.ACCEPT',
                actionButtonTitle: this.isset(modalMessageConfig.actionButtonTitle) ? modalMessageConfig.actionButtonTitle : 'BTNS.ACCEPT',
                closeButtonLabel: this.isset(modalMessageConfig.closeButtonLabel) ? modalMessageConfig.closeButtonLabel : 'BTNS.CANCELAR',
                closeButtonTitle: this.isset(modalMessageConfig.closeButtonTitle) ? modalMessageConfig.closeButtonTitle : 'BTNS.CANCELAR',
                auxiliarMessage: this.isset(modalMessageConfig.auxiliarMessage) ? modalMessageConfig.auxiliarMessage : null,
                actionButtonOption: this.isset(modalMessageConfig.actionButtonOption) ? modalMessageConfig.actionButtonOption : undefined,
                item: this.isset(modalMessageConfig.item) ? modalMessageConfig.item : undefined,
                auxiliarButtonShow: this.isset(modalMessageConfig.auxiliarButtonShow) ? modalMessageConfig.auxiliarButtonShow : false,
                auxiliarButtonLabel: this.isset(modalMessageConfig.auxiliarButtonLabel) ? modalMessageConfig.auxiliarButtonLabel : undefined,
                auxiliarButtonTitle: this.isset(modalMessageConfig.auxiliarButtonTitle) ? modalMessageConfig.auxiliarButtonTitle : undefined,
                auxiliarButtonOption: this.isset(modalMessageConfig.auxiliarButtonOption) ? modalMessageConfig.auxiliarButtonOption : undefined,
                options: this.isset(modalMessageConfig.options) ? modalMessageConfig.options : undefined,
            };
        }
    }

    // NUMBER
    preventNumberValueInput(value: any, predeterminateValue?: number): any {
        let tranformValue: any = predeterminateValue ? predeterminateValue : 0;

        if (value !== null && value !== undefined) {
            if (typeof value === 'string') {
                if (value.length > 0) {
                    tranformValue = value.replace(',', '.');
                }
            } else if (typeof value === 'number') {
                tranformValue = value;
            }
        }

        return tranformValue;
    }

    // STRING
    capitalizeLabel(label: string): string {
        return label[0] + label.substring(1, label.length);
    }

    issetString(label: string): boolean {
        return label && label !== null && label !== undefined && label.length > 0;
    }

    compareRegex(label: string, regex: string) {
        return new RegExp(regex).test(label);
    }

    /**
     * @description Return param string without spaces
     * @param  {string} label
     */
    removedSpaces(label: string): string {
        return label.replace(' ', '');
    }

    //END STRING

    getIdsFromFilterList(list: any[]): number[] {
        if (list.filter((item) => item.id !== null).map((item) => item.id).length > 0) {
            return list.filter((item) => item.id !== null).map((item) => item.id);
        }
        return [];
    }

    getDescriptionsFromFilterList(list: any[]): string[] {
        return list.filter((item) => item.description !== null).map((item) => item.description);
    }

    addFilterStatusToList(stateId: string | number, list: any[], translate: TranslateService): void {
        const stateToAdd: BasicComboItem = this.getBasicComboItemFromEnum(StateType).filter((state) => state.id === stateId)[0];
        const availableEnums = AvailableEnums;
        let valEnumPipe: ValOfEnumPipe;
        list.push({
            id: parseInt(stateToAdd.id, 10),
            description: translate.instant(valEnumPipe.transform(parseInt(stateToAdd.id, 10), availableEnums.StateType)),
        });
    }

    existDateTypeInList(list: any[], dateType: DateFilter): boolean {
        return list.filter((date) => date === dateType).length > 0;
    }

    existSentStatusInList(list: any[], providerSentStatus: EProviderSendStatus): boolean {
        return list.filter((sentStatus) => sentStatus === providerSentStatus).length > 0;
    }

    // DATABASE
    GetProvider(uri: string): Observable<any> {
        const urlIdParam = this.getSafeEndpoint(uri);
        const options = this.getDefaultRequestOptions(DBOperation.post_query);
        return this._http.get(urlIdParam, options).pipe(
            map((response: any) => {
                return this.getResponseHttpClient(response);
            })
        );
    }

    setShowNotifications(show: boolean): void {
        this.showNotifications = show;
    }

    getSetShowNotifications(): boolean {
        return this.showNotifications;
    }

    // END DATABASE

    // NAVEGATION
    navegateToFilterReservation(navegationDom: NavegationDom): void {
        if (navegationDom.project) {
            navegationDom.filterReservation.ProjectIdList = [navegationDom.project.ID];
        }

        if (navegationDom.voyage) {
            navegationDom.filterReservation.VoyageIdList = [navegationDom.voyage.ID];
        }

        const existTabProject: boolean = document.getElementById(ControlDomId.tabProject + navegationDom.project.ID) !== null;
        const existTabReference: boolean =
            this.isset(navegationDom.referenceTab) && document.getElementById(ControlDomId.closetab + navegationDom.referenceTab) !== null;
        const existTabGestion: boolean = document.getElementById(ControlDomId.gestion) !== null;

        let timeOutReservationMenuClick: number = 100;

        if (navegationDom.createTabProject) {
            const infoTab: InfoTab = {
                id_breadcum: navegationDom.project.ID,
                breadcum: navegationDom.project.Code,
                field: 'Code',
                entity: EntityType.project,
                object: navegationDom.project,
            };
            navegationDom.tabEven.emit(infoTab);
            timeOutReservationMenuClick = 3600;
            navegationDom.sameToTab = false;
        }

        if (existTabReference) {
            document.getElementById(ControlDomId.closetab + navegationDom.referenceTab + '').click();
        }

        if (navegationDom.sameToTab) {
            if (existTabProject) {
                setTimeout(
                    () => {
                        document.getElementById(ControlDomId.tabProject + navegationDom.project.ID).click();
                    },
                    existTabReference ? 200 : 100
                );
            }
        }

        if (existTabProject) {
            setTimeout(
                () => {
                    this.putClientStorage(LocalStorageKey.filterReservation, navegationDom.filterReservation, { hours: 3600 }, true);
                    document.getElementById(ControlDomId.showReservations + navegationDom.project.ID).click();
                },
                navegationDom.sameToTab ? 500 : 100
            );
        } else {
            if (existTabGestion) {
                setTimeout(
                    () => {
                        document.getElementById(ControlDomId.gestion).click();
                    },
                    navegationDom.sameToTab ? 500 : 100
                );
            }
        }
    }

    navegateToCreateReservationWithCrewList(navegateCreateReservation: INavegateCreateReservation): void {
        if (!navegateCreateReservation) {
            return;
        }

        if (!navegateCreateReservation.projectId) {
            return;
        }

        const projectTab: HTMLElement = document.getElementById(ControlDomId.tabProject + navegateCreateReservation.projectId);

        if (!projectTab) {
            return;
        }

        this.controlSetTimeOutAutocomplete(() => {
            this.putClientStorage(LocalStorageKey.createReservation, navegateCreateReservation.crewList, { hours: 6000 }, true);
            const arrow = document.getElementById(ControlDomId.arrowCollapseProject + navegateCreateReservation.projectId);

            if (!this.isset(arrow)) {
                const dropDownReserves = document.getElementById(ControlDomId.dropDownReservation);
                dropDownReserves.click();

                this.controlSetTimeOutAutocomplete(() => {
                    const createReservationMenuOption: HTMLElement = document.getElementById(
                        ControlDomId.createReservation + navegateCreateReservation.projectId
                    );

                    createReservationMenuOption.click();
                }, 200);
            }
        }, 1500);
    }

    navegateReservationListOnPorject(navegationDom: NavegationDom): void {
        setTimeout(() => {
            this.putClientStorage(LocalStorageKey.filterReservation, navegationDom.filterReservation, { hours: 6000 }, true);
            const arrow = document.getElementById(ControlDomId.arrowCollapseProject + navegationDom.project.ID);

            if (!this.isset(arrow)) {
                document.getElementById(ControlDomId.dropDownReservation).click();

                setTimeout(() => {
                    document.getElementById(ControlDomId.showReservations + navegationDom.project.ID).click();
                }, 100);
            }
        }, 1500);
    }

    // END NAVEGATION

    //FILES
    getInfoFromNameFile(fullFileName: string): { name: string; extension: string } {
        let fileName = '';
        let extension = '';

        if (fullFileName && typeof fullFileName === 'string') {
            fileName = fullFileName.replace('.' + fullFileName.split('.')[fullFileName.split('.').length - 1], '');
            extension = '.' + fullFileName.split('.')[fullFileName.split('.').length - 1].replace('..', '.');
        }
        return { name: fileName, extension: extension };
    }

    getTableNameFromEntity(et: EntityType): string {
        let tbname = '';
        switch (et) {
            case EntityType.reservation_falua:
            case EntityType.reservation:
                tbname = TableNames.Reservation;
                break;
            case EntityType.quotation:
                tbname = TableNames.TrampQuotation;
                break;
            case EntityType.pricelist:
                tbname = TableNames.PriceList;
                break;
            case EntityType.rental:
                tbname = TableNames.Rental;
                break;
            case EntityType.stock:
                tbname = TableNames.Stock;
                break;
            case EntityType.incidence:
                tbname = TableNames.Incidence;
                break;
            default:
                break;
        }
        return tbname;
    }

    getDocumentMultiUpload(docUpload: {
        event: any;
        entityId: number;
        entityType: EntityType;
        currentDoc: IDoc;
        transformByteArray: boolean;
        byteArray?: any;
        byteArrayBase64?: any;
    }): IDoc {
        const user = this.user();
        return {
            TableId: this.isset(docUpload.entityId) ? docUpload.entityId : 0,
            TableName: this.getTableNameFromEntity(docUpload.entityType),
            Name: docUpload.event.file.Name,
            Extension: docUpload.event.file.Extension,
            Type: this.isset(docUpload.event.file.Type) ? docUpload.event.file.Type : docUpload.currentDoc.Type,
            TypeId: this.isset(docUpload.event.file.TypeId) ? docUpload.event.file.TypeId : docUpload.currentDoc.TypeId,
            ByteArray: undefined,
            ByteArrayBase64: docUpload.transformByteArray ? docUpload.byteArrayBase64 : docUpload.event.file.ByteArray,
            Division: user.ActiveDivision.Id,
            CreationUser: user.UserName,
            ModificationUser: user.UserName,
            CreationDate: this.formatDateWithMoment(new Date().toString(), 'YYYY-MM-DD[T]HH:mm:ss').toString(),
            ModificationDate: this.formatDateWithMoment(new Date().toString(), 'YYYY-MM-DD').toString(),
            ModificationTime: this.formatDateWithMoment(new Date().toString(), 'HH:mm:ss').toString(),
        };
    }

    getDocumentUpload(docUpload: {
        event: any;
        entityId: number;
        entityType: EntityType;
        currentDoc: IDoc;
        transformByteArray: boolean;
        byteArray?: any;
        byteArrayBase64?: any;
    }): IDoc {
        const user = this.user();
        return {
            TableId: this.isset(docUpload.entityId) ? docUpload.entityId : 0,
            TableName: this.getTableNameFromEntity(docUpload.entityType),
            Name: docUpload.event.file.Name,
            Extension: docUpload.event.file.Extension,
            Type: this.isset(docUpload.event.file.Type) ? docUpload.event.file.Type : docUpload.currentDoc.Type,
            TypeId: this.isset(docUpload.event.file.TypeId) ? docUpload.event.file.TypeId : docUpload.currentDoc.TypeId,
            ByteArray: undefined,
            ByteArrayBase64: docUpload.transformByteArray ? docUpload.byteArrayBase64 : docUpload.event.file.ByteArray,
            Division: user.ActiveDivision.Id,
            CreationUser: user.UserName,
            ModificationUser: user.UserName,
            CreationDate: this.formatDateWithMoment(new Date().toString(), 'YYYY-MM-DD[T]HH:mm:ss').toString(),
            ModificationDate: this.formatDateWithMoment(new Date().toString(), 'YYYY-MM-DD').toString(),
            ModificationTime: this.formatDateWithMoment(new Date().toString(), 'HH:mm:ss').toString(),
        };
    }

    // END DATABASE

    // SUSCRIPTION

    removedSuscription(subscription: Subscription): void {
        if (subscription && !subscription.closed) {
            subscription.unsubscribe();
        }
    }

    removedSubject(subject: Subject<any>): void {
        if (subject && !subject.closed) {
            subject.unsubscribe();
        }
    }

    // END SUSCRIPTION

    generateRandomStringId(referenceLabel: string): string {
        const random: number = Math.random() * 20;
        return referenceLabel + random.toString().split('.')[1];
    }

    enableTooltip(): void {
        $(document).ready(function () {
            $('[data-toggle="tooltip-oilgas"]').tooltip();
        });
    }

    removedTooltip(): void {
        const topTooltip = document.querySelector('.bs-tooltip-top.show');
        if (topTooltip) {
            topTooltip.classList.remove('show');
        }
    }

    unloadPdfViewer(currentPdfviewerControl: PdfViewerComponent): void {
        if (this.isset(currentPdfviewerControl)) {
            currentPdfviewerControl.unload();
            currentPdfviewerControl.destroy();
        }
    }

    /**
     * @description Get saved exception message list on localStorage
     * @returns string
     */
    getExceptionMessageList(): string[] {
        const savedexceptionsMessageList: string[] = this.getClientStorage(CookieKeys.exceptions, true);
        return savedexceptionsMessageList ? savedexceptionsMessageList : [];
    }

    /**
     * @description Add exception message to localStorage for later use
     * @param  {string} newExceptionMessage
     * @returns void
     */
    addExceptionMessageToLocalStorage(newExceptionMessage: string): void {
        const exceptionsMessageList: string[] = this.getExceptionMessageList();

        const index = exceptionsMessageList.indexOf(newExceptionMessage);
        if (index === -1) {
            exceptionsMessageList.push(newExceptionMessage);
        }
        localStorage.setItem(CookieKeys.exceptions, JSON.stringify(exceptionsMessageList));
    }

    /**
     * @description Delete exception message from localStorage
     * @param  {string} deleteExceptionMessage
     */
    deleteExceptionMessageToLocalStorage(deleteExceptionMessage: string) {
        const exceptionsMessageList: string[] = this.getExceptionMessageList();

        const index = exceptionsMessageList.indexOf(deleteExceptionMessage);
        if (index > -1) {
            exceptionsMessageList.splice(index, 1);
        }

        localStorage.setItem(CookieKeys.exceptions, JSON.stringify(exceptionsMessageList));
    }

    /**
     * @description Collapse toggle with delay - Bootstrap collapsable needed
     * @param  {boolean} collapsableElement
     * @returns void
     */
    toggleCollapseWithDelay(collapsableElement: boolean, delay: number): void {
        const collapseToggleTimeOut: any = setTimeout(() => {
            collapsableElement = !collapsableElement;

            clearTimeout(collapseToggleTimeOut);
        }, delay || 200);
    }

    /**
     * @description Get current available height for a external iframe
     * @returns string
     */
    getHeightForExternalFrame(): string {
        const minimalHeight: number = 1400;
        const doc = document.getElementsByTagName('body');
        const modScroll = document.getElementsByClassName('mod-scroll');
        const calculateHeight: number = window.innerHeight - 20;

        const height = calculateHeight >= minimalHeight ? calculateHeight : minimalHeight;

        doc[0].style.minHeight = `${window.innerHeight}px`;

        for (let i = 0, modScrollLength = modScroll.length; i < modScrollLength; i++) {
            modScroll[i]['style'].height = `${height}px`;
        }

        return height + 'px';
    }

    /**
     * @description Execute function on timeOut with clear control
     * @param  {Function} executeFunction
     * @param  {number} timeOut
     */
    controlSetTimeOutAutocomplete(executeFunction: Function, timeOut?: number): void {
        let setTimeOutAutocomplete: any = null;
        const defaulSetTimeOut: number = 700;

        if (setTimeOutAutocomplete) {
            clearTimeout(setTimeOutAutocomplete);
        }

        setTimeOutAutocomplete = setTimeout(() => executeFunction(), timeOut ? timeOut : defaulSetTimeOut);
    }

    /**
     * @description Initializes the Iframe
     * @param  {string} urlIframe
     * @returns IFrame
     */
    initializeIframe(urlIframe: string, businessAudit?: boolean): IFrame {
        return new IFrame(this.getSanitizedUrl(urlIframe, businessAudit), this.getHeightForExternalFrame());
    }

    /**
     * @description Sanitizes iframe url
     * @param  {string} urlIframe
     * @returns SafeResourceUrl
     */
    getSanitizedUrl(urlIframe: string, businessAudit?: boolean): SafeResourceUrl {
        const token: string = this.getClientStorage(CookieKeys.token);
        urlIframe += '?token=' + token + '&client=tramping';
        if (businessAudit) {
            urlIframe += '&businessAudit=' + businessAudit;
        }

        return this.sanitizer.bypassSecurityTrustResourceUrl(urlIframe);
    }

    /**
     * @description Returns Iframe's height
     * @returns string
     */
    getIframeHeight(): string {
        const doc = document.getElementsByTagName('body');
        const modScroll = document.getElementsByClassName('mod-scroll');
        const height = window.innerHeight - 20;
        const iframeHeigth = height + 'px';
        doc[0].style.minHeight = window.innerHeight + 'px';
        for (let i = 0, modScrollLength = modScroll.length; i < modScrollLength; i++) {
            modScroll[i]['style'].height = height + 'px';
        }

        return iframeHeigth;
    }

    /**
     *
     * @description Get language id according user language
     * @param {Language[]} languageList
     * @returns number | null
     */
    public getLanguageIdAccordingUserLanguage(languageList: Language[]): number | null {
        const USER_LANGUAGE_CODE: string = this.getClientStorage(CookieKeys.language);

        if (!USER_LANGUAGE_CODE || !USER_LANGUAGE_CODE.length) {
            return null;
        }

        const LANGUAGE = languageList.find((language: Language) => language.code.trim().toLowerCase() === USER_LANGUAGE_CODE.trim().toLowerCase());

        if (!LANGUAGE || !LANGUAGE.id) {
            return null;
        }

        return LANGUAGE.id;
    }
}
