import * as moment from 'moment';

export class UtilHelper {
    static mapToObjectList<T>(result: any, mapToObjectFunction: Function): T[] {
        if (result && mapToObjectFunction) {
            const itemsToTransform: any[] = result.Data || result;

            if (itemsToTransform && itemsToTransform.length > 0) {
                return itemsToTransform.map((item: any) => {
                    if (item) {
                        return mapToObjectFunction(item);
                    }
                }) as Array<T>;
            }
        }

        return [];
    }

    static mapToBoolean(result): boolean {
        if (result) {
            const itemsToTransform: any[] = result.Data ? result.Data[0] : result;
            return typeof itemsToTransform === 'boolean' ? itemsToTransform : false;
        }

        return false;
    }

    static formatDateWithMoment(date: Date | string, resultFormat: string, origiFormat?: string): Date | string {
        if (date !== undefined && date !== null) {
            if (!origiFormat || !origiFormat.length) {
                return moment(date).format(resultFormat);
            }
            return moment(date, origiFormat).format(resultFormat);
        }

        return null;
    }

    static getObjectId<T>(result: any, defaultValue: T): T {
        if (!result || (!result.Id && !result.ID)) {
            return defaultValue;
        }

        if (!!result.Id) {
            return result.Id;
        }

        if (!!result.ID) {
            return result.ID;
        }
    }
}
